import { ADMIN_ROUTES, DASHBOARD, MY_LOCKER } from "../../routes/constants";
import { AvField, AvForm } from "availity-reactstrap-validation";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Row,
} from "reactstrap";
import { ADMIN_ROLES, PUBLIC_ROLES } from "../../roleConstants";
import Apple from "../../components/LoginApple";
import Facebook from "../../components/LoginFacebook";
import Google from "../../components/LoginGoogle";
import React from "react";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import { setUserData } from "../../redux/actions/userActions";
import { signIn } from "../../services/apiActions";

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      apiKey: null,
    };

    this.googleLoginSuccess = this.googleLoginSuccess.bind(this);
    this.fbLoginSuccess = this.fbLoginSuccess.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);

    var url = window.location.href;

    var regex = /[?&]([^=#]+)=([^&#]*)/g,
      params = {},
      match;
    while ((match = regex.exec(url))) {
      params[match[1]] = match[2];
    }

    if (params.code) {
      this.appleLoginSuccess(params.code);
    }
  }

  appleLoginSuccess(code) {
    const self = this;
    signIn(
      {
        accessToken: code,
        provider: "apple",
      },
      (response) => {
        self.props.setUserData(
          response.data.displayName,
          response.data.roles,
          response.data.jwtToken
        );
      }
    );
  }

  googleLoginSuccess(response) {
    const self = this;

    signIn(
      {
        accessToken: response.tokenId,
        provider: "google",
      },
      (response) => {
        self.props.setUserData(
          response.data.displayName,
          response.data.roles,
          response.data.jwtToken
        );
      }
    );
  }

  fbLoginSuccess(response) {
    const self = this;

    signIn(
      {
        accessToken: response.accessToken,
        provider: "facebook",
      },
      (response) => {
        self.props.setUserData(
          response.data.displayName,
          response.data.roles,
          response.data.jwtToken
        );
      }
    );
  }

  handleValidSubmit(event, formValues) {
    const self = this;
    var providerKey = [formValues.email, formValues.password].join(";");
    signIn(
      {
        accessToken: providerKey,
        provider: "kindkatch",
      },
      (response) => {
        self.props.setUserData(
          response.data.displayName,
          response.data.roles,
          response.data.jwtToken
        );
      }
    );
  }

  render() {
    if (!!this.props.apiKey) {
      if (this.props.userRoles.includes(ADMIN_ROLES.ADMINISTRATOR)) {
        return <Redirect push to={ADMIN_ROUTES.CHARITIES} />;
      } else {
        if (
          this.props.userRoles.includes(PUBLIC_ROLES.ADMINISTRATOR) ||
          this.props.userRoles.includes(PUBLIC_ROLES.CONTENT_REVIEWER)
        ) {
          return <Redirect push to={DASHBOARD} />;
        }
        return <Redirect push to={MY_LOCKER} />;
      }
    }

    return (
      <React.Fragment>
        <Card>
          <CardHeader>
            <h2>Welcome back!</h2>
          </CardHeader>
          <CardBody>
            <p className="lead">Sign in to your account to continue</p>
            <div className="m-sm-4">
              <AvForm model={{}} onValidSubmit={this.handleValidSubmit}>
                <Row>
                  <Col>
                    <AvField name="email" label="Email" type="email" required />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <AvField
                      name="password"
                      label="Password"
                      type="password"
                      required
                    />
                    <a href="/auth/reset-password">Forgot your password?</a>
                  </Col>
                </Row>
                <FormGroup className="form-group d-flex justify-content-end">
                  <Button className="mr-1 mb-1">Sign in</Button>
                </FormGroup>
              </AvForm>
            </div>
            <div className="m-sm-12">
              <div className="text-center">
                <p>OR</p>
              </div>
            </div>
            <div className="m-sm-12">
              <div className="text-center">
                <Google successCallback={this.googleLoginSuccess} />
                <Apple
                  redirectURI={process.env.REACT_APP_APPLE_SIGNIN_REDIRECT}
                  successCallback={(result) => alert(result)}
                />
              </div>
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default connect(
  (store) => ({
    userName: store.user.userName,
    apiKey: store.user.apiKey,
    userRoles: store.user.userRoles,
  }),
  (dispatch) => {
    return {
      setUserData: (displayName, userRoles, apiKey) => {
        dispatch(setUserData(displayName, userRoles, apiKey));
      },
    };
  }
)(SignIn);
