// Sidebar
export const SIDEBAR_VISIBILITY_TOGGLE = "SIDEBAR_VISIBILITY_TOGGLE";
export const SIDEBAR_VISIBILITY_SHOW = "SIDEBAR_VISIBILITY_SHOW";
export const SIDEBAR_VISIBILITY_HIDE = "SIDEBAR_VISIBILITY_HIDE";
export const SIDEBAR_STICKY_TOGGLE = "SIDEBAR_STICKY_TOGGLE";
export const SIDEBAR_STICKY_ENABLE = "SIDEBAR_STICKY_ENABLE";
export const SIDEBAR_STICKY_DISABLE = "SIDEBAR_STICKY_DISABLE";

// Layout
export const LAYOUT_BOXED_TOGGLE = "LAYOUT_BOXED_TOGGLE";
export const LAYOUT_BOXED_ENABLE = "LAYOUT_BOXED_ENABLE";
export const LAYOUT_BOXED_DISABLE = "LAYOUT_BOXED_DISABLE";

// Theme
export const THEME_TOGGLE = "THEME_TOGGLE";

// User
export const SIGN_OUT = "SIGN_OUT";
export const SET_USER_DATA = "SET_USER_DATA";

// Alerts
export const PUSH_ALERT = "PUSH_ALERT";
export const DISMISS_ALERT = "DISMISS_ALERT";

// Debug
export const DEBUG_UPDATE_ROLES = "DEBUG_UPDATE_ROLES";

// Admins
export const ADD_ADMIN = "ADD_ADMIN";
export const EDIT_ADMIN = "EDIT_ADMIN";
export const SET_ADMINS = "SET_ADMINS";
export const SET_ADMINS_DIRTY = "SET_ADMINS_DIRTY";

// Charities
export const SET_CHARITIES = "SET_CHARITIES";
export const SET_CHARITIES_DIRTY = "SET_CHARITIES_DIRTY";

// Media
export const SET_MEDIA = "SET_MEDIA";
export const EDIT_MEDIA = "EDIT_MEDIA";
export const SET_MEDIA_DIRTY = "SET_MEDIA_DIRTY";
export const SET_TAG_FILTERS = "SET_TAG_FILTERS";
export const SET_CHILDORGANIZATION_FILTERS = "SET_CHILDORGANIZATION_FILTERS";
export const SET_START_DATE_FILTER = "SET_START_DATE_FILTER";
export const SET_END_DATE_FILTER = "SET_END_DATE_FILTER";
export const SET_STATUS_FILTERS = "SET_STATUS_FILTERS";
export const TOGGLE_STATUS_FILTER = "TOGGLE_STATUS_FILTER";
export const SET_PAGE_NUMBER = "SET_PAGE_NUMBER";

// Donations
export const SET_DONATIONS_DIRTY = "SET_DONATIONS_DIRTY";
export const SET_DONATIONS = "SET_DONATIONS";
export const SET_DONATIONS_NAME_FILTER = "SET_DONATIONS_NAME_FILTER";
export const SET_DONATIONS_START_DATE_FILTER =
  "SET_DONATIONS_START_DATE_FILTER";
export const SET_DONATIONS_END_DATE_FILTER = "SET_DONATIONS_END_DATE_FILTER";

// Donors
export const SET_SEARCH_FILTER = "SET_SEARCH_FILTER";

// Share
export const REMOVE_RECIPIENT = "REMOVE_RECIPIENT";
export const ADD_MANY_RECIPIENTS = "ADD_MANY_RECIPIENTS";

// Messages
export const SET_THREADS = "SET_THREADS";
export const SET_THREADS_DIRTY = "SET_THREADS_DIRTY";
