import * as types from "../constants";
import Cookies from 'js-cookie';

const initialState = {
  userName: null,
  userRoles: null,
  apiKey: null
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.SIGN_OUT:
      Cookies.remove("apiKey");
      return {
        ...state,
        apiKey: null,
        userRoles: null,
        userName: null
      };

    case types.SET_USER_DATA:
      Cookies.set("displayName", actions.displayName);
      Cookies.set("userRoles", actions.userRoles);
      Cookies.set("apiKey", actions.apiKey);
      return {
        ...state,
        userName: actions.displayName,
        userRoles: actions.userRoles,
        apiKey: actions.apiKey
      };

    case types.DEBUG_UPDATE_ROLES:
      Cookies.set("userRoles", actions.roles);
      return {
        ...state,
        userRoles: actions.roles,
      };

    default:
      if (
        (!state.userName || !state.userRoles || !state.apiKey)
        &&
        (!!Cookies.get("displayName") && !!Cookies.get("userRoles") && !!Cookies.get("apiKey"))
      ) {
        return {
          ...state,
          userName: Cookies.get("displayName"),
          userRoles: JSON.parse(Cookies.get("userRoles")),
          apiKey: Cookies.get("apiKey")
        };
      } else {
        return state;
      }
  }
}
